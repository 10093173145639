form {
    padding: 40px 0;
}

/* break container for full width */
.left {
    float: left;
}

.container {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}

#portfolio {
    padding: 0;
}

#mainNav .container {
    border-bottom: none;
    padding: 20px 40px 40px 40px;
}

.navbar-custom {
    &.affix {
        padding: 0;
    }
    .navbar-brand span {
        display: table;
    }
}

h4 span, h5 span {
    display: table;
}

.navbar-brand {
    padding: 10px 15px;
}

.btn-xl {
    padding: 5px 20px;
    width: 100%;
    text-align: left;
}

#services {
    padding: 110px 0 0 0;
}

.service-heading {
    margin: 30px 0;
}
.forgotpass {
    margin-left: 20px;
}
.col-logo{
    display:flex;
    align-items:center;
    justify-content:center;
    height: 150px;
}
.col-half-offset{

    img {
        max-height: 80px;
    }

    margin-left:4.166666667%;
}

.formtop {
    margin-top: -20px;

    h5 {
        border-top: 2px solid #16b7cd;
        margin: 0px 0 10px 0;
        padding: 20px 0 40px 0;
    }

    .dropcontainer {
        padding-left: 0;
    }
}

.addtxt {
    margin-top: 20px;
}

input#remember {
    margin-right: 5px;
}

.form-control {
    border-radius: 0;
    border: none;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, cursive;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 2px solid #16b7cd;
    margin: 10px 0;
    padding: 10px;
    height: auto;
}

.btn-group {
    width: 100%;
    position: relative;
}

.btn {
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, cursive;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 2px solid;
    margin: 10px 0;
    padding: 10px;
    height: auto;
    width: 100%;
    text-align: left;
    position: relative;
}

.btn-group i {
    position: absolute;
    right: 20px;
    top: 25px;
    z-index: 99;
    color: #16b7cd;
}

.block-text {
    margin: 0px;
}

.dropdown-menu {
    min-width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

footer {
    text-align: right;
    font-size: 12px;
}

.gridsection {
    overflow: hidden;
}

/*Blcoks in general*/
.gridsection {
    padding-top: 0px;
}

.block-container {
    width: 80%;
    margin-left: 15px;
    margin-bottom: 15px;
}

.block-container .block {
    padding: 10px;
    margin-top: 30px
}

@media (max-width: 1200px) {
    .block-container {
        width: 100%;
    }
}

/*Category block*/
.category-block .inner {
    background-color: #ffffff;
}

.category-block {

}

.category-block .image {
    width: 100%;
    height: 18vw;
    background-position: center center;
    background-size: cover;
}

.category-block a {
    color: #000000;
}

.category-block a:hover {
    color: #000000;
    text-decoration: none;
}

.category-block .caption {
    min-height: 50px;
    border-bottom: solid 3px #16b7cd;
    color: #16b7cd;
}

.category-block h4 {
    padding: 15px;
}

@media (max-width: 1200px) {
    .category-block .image {
        height: 24vw;
    }
}

/*font block*/
.font-block {
    background-color: #ffffff;
    margin-left: 30px;
    padding: 30px;
}

.font-block .title {
    padding-bottom: 15px;
}

.font-block .example {
    font-size: 32px;
}

/*text block*/

.text-block .title {
    font-weight: 600;
    padding-bottom: 15px;
}

.text-block {
    padding: 15px;
}

/*color block*/

.color-block {
    background-color: #ffffff;
    padding: 30px;
    margin-left: 30px;
}

.color-block .title {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 600;
}

.color-block .color {
    float: left;
    width: 150px;
}

.color-block .color .name {
    font-weight: 400;
    margin-bottom: 10px;
}

.color-block .color-circle {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.color-block .texts {
    margin-top: 15px;
}

/*donwload block*/
.download-block .inner {
    background-color: #ffffff;
}

.download-block {

}

.download-block .image {
    width: 100%;
    height: 18vw;
    background-position: center center;
    background-size: cover;
}

.download-block .caption {
    min-height: 60px;
    border-bottom: solid 3px #16b7cd;
    color: #16b7cd;
}

.download-block .left {
    //width: 80%;
}

.download-block a {
    color: #000000;
}

.download-block a:hover {
    color: #000000;
    text-decoration: none;
}

.download-block .download {
    margin: 10px 0 0 15px;
    font-weight: 600;
}

.download-block .title {
    margin: 0 0 0 15px;
    font-weight: 200;
}

.caption i.fa.fa-download {
    font-size: 32px;
    float: right;
    padding: 15px;
}

@media (max-width: 1200px) {
    .download-block .image {
        height: 24vw;
    }
}

@media (max-width: 767px) {
    .navbar-toggle {
        display: none;
    }
    .container > .navbar-header {
        float: left;
    }
    #services {
        padding: 100px 0 0 0;
    }

    .block-container {
        margin-left: 0;
    }

    .block-container .block {
        padding: 10px;
    }
}

@media (max-width: 500px) {
    .navbar-brand h5{
        display: none;
    }
}
