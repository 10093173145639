body {
    overflow-x: hidden;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.text-muted {
    color: #777777;
}

.text-primary {
    color: #16b7cd;
}

p {
    font-size: 14px;
    line-height: 1.75;
    &.large {
        font-size: 16px;
    }
}

a {
    outline: none;
    &:hover, &:focus, &:active, &.active {
        outline: none;
    }
    color: #16b7cd;
    &:hover, &:focus, &:active, &.active {
        color: #16b7cd;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
}

.img-centered {
    margin: 0 auto;
}

.bg-light-gray {
    background-color: #eeeeee;
}

.bg-darkest-gray {
    background-color: #222222;
}

.btn-primary {
    color: white;
    background-color: #16b7cd;
    border-color: #16b7cd;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    &:hover, &:focus, &:active, &.active {
        color: white;
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

.open .dropdown-toggle.btn-primary {
    color: white;
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-primary {
    &:active, &.active {
        background-image: none;
    }
}

.btn-group > .dropdown-toggle {
    border-bottom: 2px solid #16b7cd;
}

.open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary {
    &.disabled, &[disabled] {
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

fieldset[disabled] .btn-primary {
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-primary {
    &.disabled:hover, &[disabled]:hover {
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

fieldset[disabled] .btn-primary:hover {
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-primary {
    &.disabled:focus, &[disabled]:focus {
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

fieldset[disabled] .btn-primary:focus {
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-primary {
    &.disabled:active, &[disabled]:active {
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

fieldset[disabled] .btn-primary:active {
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-primary {
    &.disabled.active, &[disabled].active {
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

fieldset[disabled] .btn-primary.active {
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-primary .badge {
    color: #16b7cd;
    background-color: white;
}

.btn-xl {
    color: white;
    background-color: #16b7cd;
    border-color: #16b7cd;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 3px;
    font-size: 18px;
    padding: 20px 40px;
    &:hover, &:focus, &:active, &.active {
        color: white;
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

.open .dropdown-toggle.btn-xl {
    color: white;
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-xl {
    &:active, &.active {
        background-image: none;
    }
}

.open .dropdown-toggle.btn-xl {
    background-image: none;
}

.btn-xl {
    &.disabled, &[disabled] {
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

fieldset[disabled] .btn-xl {
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-xl {
    &.disabled:hover, &[disabled]:hover {
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

fieldset[disabled] .btn-xl:hover {
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-xl {
    &.disabled:focus, &[disabled]:focus {
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

fieldset[disabled] .btn-xl:focus {
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-xl {
    &.disabled:active, &[disabled]:active {
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

fieldset[disabled] .btn-xl:active {
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-xl {
    &.disabled.active, &[disabled].active {
        background-color: #16b7cd;
        border-color: #16b7cd;
    }
}

fieldset[disabled] .btn-xl.active {
    background-color: #16b7cd;
    border-color: #16b7cd;
}

.btn-xl .badge {
    color: #16b7cd;
    background-color: white;
}

.navbar-custom {
    background-color: #ffffff !important;
    border-color: transparent;
    .navbar-brand {
        color: #16b7cd;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, cursive;
        line-height: 26px;
        &:hover, &:focus, &:active, &.active {
            color: #16b7cd;
        }
        img {
            max-width: 80px;
        }
    }
    .navbar-collapse {
        border-color: rgba(255, 255, 255, 0.02);
    }
    .navbar-toggle {
        background-color: #16b7cd;
        border-color: #16b7cd;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        color: white;
        font-size: 12px;
        &:hover, &:focus {
            background-color: #16b7cd;
        }
    }
    .nav li a {
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1px;
        color: white;
        &:hover, &:focus {
            color: #16b7cd;
            outline: none;
        }
    }
    .navbar-nav > .active > a {
        border-radius: 0;
        color: white;
        background-color: #16b7cd;
        &:hover, &:focus {
            color: white;
            background-color: #16b7cd;
        }
    }
}

@media (min-width: 768px) {
    .navbar-custom {
        background-color: transparent;
        -webkit-transition: padding 0.3s;
        -moz-transition: padding 0.3s;
        transition: padding 0.3s;
        border: none;
        .navbar-brand {
            font-size: 2em;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
            color: #16b7cd;
            font-weight: bold;
        }
        .navbar-nav > .active > a {
            border-radius: 3px;
        }
    }
}

@media (min-width: 768px) {
    .navbar-custom.affix {
        background-color: #222222;
        padding: 10px 0;
        .navbar-brand {
            font-size: 1.5em;
        }
    }
}

header {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    text-align: center;
    color: white;
    .intro-text {
        padding-top: 100px;
        padding-bottom: 50px;
        .intro-lead-in {
            font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-style: italic;
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 25px;
        }
        .intro-heading {
            font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 50px;
            line-height: 50px;
            margin-bottom: 25px;
        }
    }
}

@media (min-width: 768px) {
    header .intro-text {
        padding-top: 300px;
        padding-bottom: 200px;
        .intro-lead-in {
            font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-style: italic;
            font-size: 40px;
            line-height: 40px;
            margin-bottom: 25px;
        }
        .intro-heading {
            font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 75px;
            line-height: 75px;
            margin-bottom: 50px;
        }
    }
}

section {
    padding: 100px 0;
    h2.section-heading {
        font-size: 40px;
        margin-top: 0;
        margin-bottom: 15px;
    }
    h3.section-subheading {
        font-size: 16px;
        font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: none;
        font-style: italic;
        font-weight: 400;
        margin-bottom: 75px;
    }
}

@media (min-width: 768px) {
    section {
        padding: 150px 0;
    }
}

.service-heading {
    margin: 15px 0;
    text-transform: none;
}

#portfolio {
    .portfolio-item {
        margin: 0 0 15px;
        right: 0;
        .portfolio-link {
            display: block;
            position: relative;
            max-width: 400px;
            margin: 0 auto;
            .portfolio-hover {
                background: rgba(254, 209, 54, 0.9);
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: all ease 0.5s;
                -webkit-transition: all ease 0.5s;
                -moz-transition: all ease 0.5s;
                &:hover {
                    opacity: 1;
                }
                .portfolio-hover-content {
                    position: absolute;
                    width: 100%;
                    height: 20px;
                    font-size: 20px;
                    text-align: center;
                    top: 50%;
                    margin-top: -12px;
                    color: white;
                    i {
                        margin-top: -12px;
                    }
                    h3, h4 {
                        margin: 0;
                    }
                }
            }
        }
        .portfolio-caption {
            max-width: 400px;
            margin: 0 auto;
            background-color: white;
            text-align: center;
            padding: 25px;
            h4 {
                text-transform: none;
                margin: 0;
            }
            p {
                font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-style: italic;
                font-size: 16px;
                margin: 0;
            }
        }
    }
    * {
        z-index: 2;
    }
}

@media (min-width: 767px) {
    #portfolio .portfolio-item {
        margin: 0 0 30px;
    }
}

.timeline {
    list-style: none;
    padding: 0;
    position: relative;
    &:before {
        top: 0;
        bottom: 0;
        position: absolute;
        content: "";
        width: 2px;
        background-color: #f1f1f1;
        left: 40px;
        margin-left: -1.5px;
    }
    > li {
        margin-bottom: 50px;
        position: relative;
        min-height: 50px;
        &:before {
            content: " ";
            display: table;
        }
        &:after {
            content: " ";
            display: table;
            clear: both;
        }
        .timeline-panel {
            width: 100%;
            float: right;
            padding: 0 20px 0 100px;
            position: relative;
            text-align: left;
            &:before {
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
            }
            &:after {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
            }
        }
        .timeline-image {
            left: 0;
            margin-left: 0;
            width: 80px;
            height: 80px;
            position: absolute;
            z-index: 100;
            background-color: #16b7cd;
            color: white;
            border-radius: 100%;
            border: 7px solid #f1f1f1;
            text-align: center;
            h4 {
                font-size: 10px;
                margin-top: 12px;
                line-height: 14px;
            }
        }
        &.timeline-inverted > .timeline-panel {
            float: right;
            text-align: left;
            padding: 0 20px 0 100px;
            &:before {
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
            }
            &:after {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .timeline-heading h4 {
        margin-top: 0;
        color: inherit;
        &.subheading {
            text-transform: none;
        }
    }
    .timeline-body > {
        p, ul {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 768px) {
    .timeline {
        &:before {
            left: 50%;
        }
        > li {
            margin-bottom: 100px;
            min-height: 100px;
            .timeline-panel {
                width: 41%;
                float: left;
                padding: 0 20px 20px 30px;
                text-align: right;
            }
            .timeline-image {
                width: 100px;
                height: 100px;
                left: 50%;
                margin-left: -50px;
                h4 {
                    font-size: 13px;
                    margin-top: 16px;
                    line-height: 18px;
                }
            }
            &.timeline-inverted > .timeline-panel {
                float: right;
                text-align: left;
                padding: 0 30px 20px 20px;
            }
        }
    }
}

@media (min-width: 992px) {
    .timeline > li {
        min-height: 150px;
        .timeline-panel {
            padding: 0 20px 20px;
        }
        .timeline-image {
            width: 150px;
            height: 150px;
            margin-left: -75px;
            h4 {
                font-size: 18px;
                margin-top: 30px;
                line-height: 26px;
            }
        }
        &.timeline-inverted > .timeline-panel {
            padding: 0 20px 20px;
        }
    }
}

@media (min-width: 1200px) {
    .timeline > li {
        min-height: 170px;
        .timeline-panel {
            padding: 0 20px 20px 100px;
        }
        .timeline-image {
            width: 170px;
            height: 170px;
            margin-left: -85px;
            h4 {
                margin-top: 40px;
            }
        }
        &.timeline-inverted > .timeline-panel {
            padding: 0 100px 20px 20px;
        }
    }
}

.team-member {
    text-align: center;
    margin-bottom: 50px;
    img {
        margin: 0 auto;
        border: 7px solid white;
    }
    h4 {
        margin-top: 25px;
        margin-bottom: 0;
        text-transform: none;
    }
    p {
        margin-top: 0;
    }
}

aside.clients img {
    margin: 50px auto;
}

section#contact {
    background-color: #222222;
    background-position: center;
    background-repeat: no-repeat;
    .section-heading {
        color: white;
    }
    .form-group {
        margin-bottom: 25px;
        input, textarea {
            padding: 20px;
        }
        input.form-control {
            height: auto;
        }
        textarea.form-control {
            height: 236px;
        }
    }
    .form-control:focus {
        border-color: #16b7cd;
        box-shadow: none;
    }
    ::-webkit-input-placeholder {
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        color: #eeeeee;
    }
    :-moz-placeholder {
        /* Firefox 18- */
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        color: #eeeeee;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        color: #eeeeee;
    }
    :-ms-input-placeholder {
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        color: #eeeeee;
    }
    .text-danger {
        color: #e74c3c;
    }
}

footer {
    padding: 25px 0;
    text-align: center;
    span.copyright {
        line-height: 40px;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        text-transform: none;
    }
    ul.quicklinks {
        margin-bottom: 0;
        line-height: 40px;
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        text-transform: none;
    }
}

ul.social-buttons {
    margin-bottom: 0;
    li a {
        display: block;
        background-color: #222222;
        height: 40px;
        width: 40px;
        border-radius: 100%;
        font-size: 20px;
        line-height: 40px;
        color: white;
        outline: none;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        &:hover, &:focus, &:active {
            background-color: #16b7cd;
        }
    }
}

.btn {
    &:focus, &:active, &.active, &:active:focus {
        outline: none;
    }
}

.portfolio-modal {
    .modal-dialog {
        margin: 0;
        height: 100%;
        width: auto;
    }
    .modal-content {
        border-radius: 0;
        background-clip: border-box;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none;
        min-height: 100%;
        padding: 100px 0;
        text-align: center;
        h2 {
            margin-bottom: 15px;
            font-size: 3em;
        }
        p {
            margin-bottom: 30px;
            &.item-intro {
                margin: 20px 0 30px;
                font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-style: italic;
                font-size: 16px;
            }
        }
        ul.list-inline {
            margin-bottom: 30px;
            margin-top: 0;
        }
        img {
            margin-bottom: 30px;
        }
    }
    .close-modal {
        position: absolute;
        width: 75px;
        height: 75px;
        background-color: transparent;
        top: 25px;
        right: 25px;
        cursor: pointer;
        &:hover {
            opacity: 0.3;
        }
        .lr {
            height: 75px;
            width: 1px;
            margin-left: 35px;
            background-color: #222222;
            transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            /* IE 9 */
            -webkit-transform: rotate(45deg);
            /* Safari and Chrome */
            z-index: 1051;
            .rl {
                height: 75px;
                width: 1px;
                background-color: #222222;
                transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                /* IE 9 */
                -webkit-transform: rotate(90deg);
                /* Safari and Chrome */
                z-index: 1052;
            }
        }
    }
    .modal-backdrop {
        opacity: 0;
        display: none;
    }
}

::-moz-selection, ::selection {
    text-shadow: none;
    background: #16b7cd;
}

img {
    &::selection, &::-moz-selection {
        background: transparent;
    }
}

body {
    webkit-tap-highlight-color: #16b7cd;
}

/* custom dropdown */

.dropdown-menu {
    > {
        li > {
            label {
                display: block;
                padding: 3px 20px;
                clear: both;
                font-weight: normal;
                line-height: 1.42857143;
                color: #333333;
                white-space: nowrap;
                &:hover, &:focus {
                    text-decoration: none;
                    color: #262626;
                    background-color: #f5f5f5;
                }
            }
            input:checked ~ label {
                color: #ffffff;
                text-decoration: none;
                outline: 0;
                background-color: #428bca;
                &:hover, &:focus {
                    color: #ffffff;
                    text-decoration: none;
                    outline: 0;
                    background-color: #428bca;
                }
            }
        }
        .active > label {
            color: #ffffff;
            text-decoration: none;
            outline: 0;
            background-color: #428bca;
            &:hover, &:focus {
                color: #ffffff;
                text-decoration: none;
                outline: 0;
                background-color: #428bca;
            }
        }
        li > input[disabled] ~ label {
            color: #999999;
            &:hover, &:focus {
                color: #999999;
            }
        }
        .disabled > label {
            color: #999999;
            &:hover, &:focus {
                color: #999999;
            }
        }
        li > input[disabled] ~ label {
            &:hover, &:focus {
                text-decoration: none;
                background-color: transparent;
                background-image: none;
                filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
                cursor: not-allowed;
            }
        }
        .disabled > label {
            &:hover, &:focus {
                text-decoration: none;
                background-color: transparent;
                background-image: none;
                filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
                cursor: not-allowed;
            }
        }
        li > {
            label {
                margin-bottom: 0;
                cursor: pointer;
            }
            input {
                &[type="radio"], &[type="checkbox"] {
                    display: none;
                    position: absolute;
                    top: -9999em;
                    left: -9999em;
                }
            }
            label:focus, input:focus ~ label {
                outline: thin dotted;
                outline: 5px auto -webkit-focus-ring-color;
                outline-offset: -2px;
            }
        }
    }
    &.pull-right {
        right: 0;
        left: auto;
    }
    &.pull-top {
        bottom: 100%;
        top: auto;
        margin: 0 0 2px;
        -webkit-box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.175);
    }
    &.pull-center {
        right: 50%;
        left: auto;
    }
    &.pull-middle {
        right: 100%;
        margin: 0 2px 0 0;
        box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2);
        left: auto;
        &.pull-right {
            right: auto;
            left: 100%;
            margin: 0 0 0 2px;
            box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2);
        }
        &.pull-center {
            right: 50%;
            margin: 0;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }
    }
    &.bullet {
        margin-top: 8px;
        &:before {
            width: 0;
            height: 0;
            content: '';
            display: inline-block;
            position: absolute;
            border-color: transparent;
            border-style: solid;
            -webkit-transform: rotate(360deg);
            border-width: 0 7px 7px;
            border-bottom-color: #cccccc;
            border-bottom-color: rgba(0, 0, 0, 0.15);
            top: -7px;
            left: 9px;
        }
        &:after {
            width: 0;
            height: 0;
            content: '';
            display: inline-block;
            position: absolute;
            border-color: transparent;
            border-style: solid;
            -webkit-transform: rotate(360deg);
            border-width: 0 6px 6px;
            border-bottom-color: #ffffff;
            top: -6px;
            left: 10px;
        }
        &.pull-right {
            &:before {
                left: auto;
                right: 9px;
            }
            &:after {
                left: auto;
                right: 10px;
            }
        }
        &.pull-top {
            margin-top: 0;
            margin-bottom: 8px;
            &:before {
                top: auto;
                bottom: -7px;
                border-bottom-width: 0;
                border-top-width: 7px;
                border-top-color: #cccccc;
                border-top-color: rgba(0, 0, 0, 0.15);
            }
            &:after {
                top: auto;
                bottom: -6px;
                border-bottom: none;
                border-top-width: 6px;
                border-top-color: #ffffff;
            }
        }
        &.pull-center {
            &:before {
                left: auto;
                right: 50%;
                margin-right: -7px;
            }
            &:after {
                left: auto;
                right: 50%;
                margin-right: -6px;
            }
        }
        &.pull-middle {
            margin-right: 8px;
            &:before {
                top: 50%;
                left: 100%;
                right: auto;
                margin-top: -7px;
                border-right-width: 0;
                border-bottom-color: transparent;
                border-top-width: 7px;
                border-left-color: #cccccc;
                border-left-color: rgba(0, 0, 0, 0.15);
            }
            &:after {
                top: 50%;
                left: 100%;
                right: auto;
                margin-top: -6px;
                border-right-width: 0;
                border-bottom-color: transparent;
                border-top-width: 6px;
                border-left-color: #ffffff;
            }
            &.pull-right {
                margin-right: 0;
                margin-left: 8px;
                &:before {
                    left: -7px;
                    border-left-width: 0;
                    border-right-width: 7px;
                    border-right-color: #cccccc;
                    border-right-color: rgba(0, 0, 0, 0.15);
                }
                &:after {
                    left: -6px;
                    border-left-width: 0;
                    border-right-width: 6px;
                    border-right-color: #ffffff;
                }
            }
            &.pull-center {
                margin-left: 0;
                margin-right: 0;
                &:before, &:after {
                    border: none;
                    display: none;
                }
            }
        }
    }
}

.dropdown-submenu {
    position: relative;
    > {
        .dropdown-menu {
            top: 0;
            left: 100%;
            margin-top: -6px;
            margin-left: -1px;
            border-top-left-radius: 0;
        }
        a:before {
            display: block;
            float: right;
            width: 0;
            height: 0;
            content: "";
            margin-top: 6px;
            margin-right: -8px;
            border-width: 4px 0 4px 4px;
            border-style: solid;
            border-left-style: dashed;
            border-top-color: transparent;
            border-bottom-color: transparent;
        }
    }
}

@media (max-width: 767px) {
    .navbar-nav {
        .dropdown-submenu > a {
            &:before {
                margin-top: 8px;
                border-color: inherit;
                border-style: solid;
                border-width: 4px 4px 0;
                border-left-color: transparent;
                border-right-color: transparent;
            }
            padding-left: 40px;
        }
        > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > {
            a, label {
                padding-left: 35px;
            }
            .dropdown-menu > li > {
                a, label {
                    padding-left: 45px;
                }
                .dropdown-menu > li > {
                    a, label {
                        padding-left: 55px;
                    }
                    .dropdown-menu > li > {
                        a, label {
                            padding-left: 65px;
                        }
                        .dropdown-menu > li > {
                            a, label {
                                padding-left: 75px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.navbar-default .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a {
    background-color: #e7e7e7;
    color: #555555;
    &:hover, &:focus {
        background-color: #e7e7e7;
        color: #555555;
    }
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a:before {
        border-top-color: #555555;
    }
}

.navbar-inverse .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a {
    background-color: #080808;
    color: #ffffff;
    &:hover, &:focus {
        background-color: #080808;
        color: #ffffff;
    }
}

@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a:before {
        border-top-color: #ffffff;
    }
}
